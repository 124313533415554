import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type CreateOrganizationDialogForm = {
  name: string
}

export type CreateOrganizationDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (args: { name: string }) => Promise<unknown>
}

export default function CreateOrganizationDialog(props: CreateOrganizationDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm()
    }
  }, [props.open])

  const form = useForm<CreateOrganizationDialogForm>({ defaultValues: { name: '' } })

  const resetForm = () => {
    form.reset({ name: '' })
  }

  const onSubmit = ({ name }: CreateOrganizationDialogForm) => {
    props
      .onSubmit({ name })
      .then(() => props.onClose())
      .then(() => {
        props.onClose()
      })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => resetForm() }}
    >
      <Form form={form}>
        <DialogTitle>Organizaion追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="name" label="Organization名" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
