import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type AddOwnerDialogForm = {
  ownerId: string
}

export type AddOwnerDialogProps = {
  open: boolean
  organizationId: string
  organizationName: string
  onClose: () => void
  onSubmit: (args: { ownerId: string; organizationId: string }) => Promise<unknown>
}

export default function AddOwnerDialog(props: AddOwnerDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm()
    }
  }, [props.open])

  const form = useForm<AddOwnerDialogForm>({ defaultValues: { ownerId: '' } })

  const resetForm = () => {
    form.reset({ ownerId: '' })
  }

  const onSubmit = ({ ownerId }: AddOwnerDialogForm) => {
    props
      .onSubmit({ ownerId, organizationId: props.organizationId })
      .then(() => props.onClose())
      .then(() => {
        props.onClose()
      })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => resetForm() }}
    >
      <Form form={form}>
        <DialogTitle>Owner追加({props.organizationName})</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="ownerId" label="owner id" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
