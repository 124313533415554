import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type AddUserDialogForm = {
  userId: string
}

export type AddUserDialogProps = {
  open: boolean
  onClose: () => void
  deviceGroupId: string
  onSubmit: (args: { userId: string }) => Promise<void>
}

export default function AddUserDialog(props: AddUserDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm()
    }
  }, [props.open])

  const form = useForm<AddUserDialogForm>({ defaultValues: { userId: '' } })

  const resetForm = () => {
    form.reset({ userId: '' })
  }

  const onSubmit = ({ userId }: AddUserDialogForm) => {
    props.onSubmit({ userId }).then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => resetForm() }}
    >
      <Form form={form}>
        <DialogTitle>ユーザー追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="userId" label="ユーザーID" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
