import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useDevices } from '../../hooks/useDevices'
import DeviceRow from './DeviceRow'
import RemoveDeviceDialog, { type RemoveDeviceDialogProps } from './RemoveDeviceDialog'
import UpdateDialog, { type UpdateDialogProps } from './UpdateDialog'

const initaDeleteDeviceDialogProps: Omit<RemoveDeviceDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceId: '',
  deviceName: ''
} as const

const initaUpdateDialogProps: Omit<UpdateDialogProps, 'onClose' | 'onSubmit'> = {
  open: false,
  deviceId: '',
  deviceName: '',
  takePhotoSettings: 'default',
} as const

export default function Devices() {
  const { state, getDevices, deleteDevice, updateDevice } = useDevices()
  const [deleteDeviceDialogProps, setDeleteDeviceDialogProps] = useState(initaDeleteDeviceDialogProps)
  const [udpateDialogProps, setUpdateDialogProps] = useState(initaUpdateDialogProps)

  useEffect(() => {
    getDevices()
  }, [getDevices])

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>foundness</TableCell>
              <TableCell>actId</TableCell>
              <TableCell>act名</TableCell>
              <TableCell>takePhotoStatus</TableCell>
              <TableCell>updatedAt</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.ids.map((id) => {
              const device = state.devices[id]
              return (
                <DeviceRow
                  key={device._id}
                  device={device}
                  onRemove={(deviceId, deviceName) => {
                    setDeleteDeviceDialogProps({
                      open: true,
                      deviceId,
                      deviceName
                    })
                  }}
                  onEdit={(deviceId) => {
                    setUpdateDialogProps({
                      open: true,
                      deviceId,
                      deviceName: device.name,
                      takePhotoSettings: device.takePhotoSettings
                    })
                  }}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <RemoveDeviceDialog
        {...deleteDeviceDialogProps}
        onClose={() => setDeleteDeviceDialogProps(initaDeleteDeviceDialogProps)}
        onSubmit={async (deviceId) => {
          const res = await deleteDevice(deviceId)
          if (!res.ok) {
            alert(res.body)
          }
        }}
      />
      <UpdateDialog
        {...udpateDialogProps}
        onClose={() => setUpdateDialogProps({ ...initaUpdateDialogProps })}
        onSubmit={async (deviceId, args) => {
          const res = await updateDevice(deviceId, {
            takePhotoSettings: args.takePhotoSettings
          })
          if (!res.ok) {
            alert(res.body)
          }
        }}
      />
    </Box>
  )
}
