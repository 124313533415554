import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type AddDeviceDialogForm = {
  deviceId: string
}

export type AddDeviceDialogProps = {
  open: boolean
  onClose: () => void
  deviceGroupId: string
  onSubmit: (args: { deviceId: string }) => Promise<unknown>
}

export default function AddDeviceDialog(props: AddDeviceDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm()
    }
  }, [props.open])

  const form = useForm<AddDeviceDialogForm>({ defaultValues: { deviceId: '' } })

  const resetForm = () => {
    form.reset({ deviceId: '' })
  }

  const onSubmit = ({ deviceId }: AddDeviceDialogForm) => {
    props.onSubmit({ deviceId }).then(() => props.onClose())
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => resetForm() }}
    >
      <Form form={form}>
        <DialogTitle>デバイス追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="deviceId" label="デバイスID" />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
