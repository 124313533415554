import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { systemAdminClients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'
import { flatten } from '../lib/util.js'

type ActcastDeviceGroup = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/actcastGroups']['GET']['client']>
>['body']['actcastGroups'][number]

type ActcastGroupState = {
  ids: string[]
  actcastGroup: Record<string, ActcastDeviceGroup>
}

const actcastGroupState = atom<ActcastGroupState>({
  key: 'systemAdmin:actcastGroup',
  default: {
    ids: [],
    actcastGroup: {}
  }
})

export const useActcastGroup = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [actcastGroup, setActcastGroup] = useRecoilState(actcastGroupState)

  const getActcastGroups = useCallback(async () => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/actcastGroups'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    const { ids, record } = flatten(res.body.actcastGroups)
    setActcastGroup({
      ids,
      actcastGroup: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setActcastGroup])

  const createActcastGroups = useCallback(
    async (args: {
      body: Parameters<(typeof systemAdminClients)['/api/systemAdmin/actcastGroups']['POST']['client']>[0]['body']
    }) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastGroups'].POST.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body: args.body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )
  type UpdateArgs = Parameters<
    (typeof systemAdminClients)['/api/systemAdmin/actcastGroups/:actcastGroupId']['PUT']['client']
  >[0]
  const updateActcastGroup = useCallback(
    async (args: { params: UpdateArgs['params']; body: UpdateArgs['body'] }) => {
      const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
      const res = await systemAdminClients['/api/systemAdmin/actcastGroups/:actcastGroupId'].PUT.client({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: args.params,
        body: args.body
      })

      return res
    },
    [getAccessTokenSilently, getAccessTokenWithPopup]
  )

  return {
    state: actcastGroup,
    getActcastGroups,
    createActcastGroups,
    updateActcastGroup
  }
}
