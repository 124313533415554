import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField, useFieldArray } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type UpdateActcastGroupForm = {
  token: string
  visnuCloudFlag: 'on' | 'off'
  skipSlackNotificationActcastIds: { value: string }[]
}

export type UpdateActcastGroupDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (args: {
    token: string
    visnuCloudFlag: boolean
    skipSlackNotificationActcastIds: string[]
  }) => Promise<unknown>
  _id: string
  groupId: string
  token: string
  visnuCloudFlag: boolean
  skipSlackNotificationActcastIds: string[]
}

export default function UpdateActcastGroupDialog(props: UpdateActcastGroupDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm({
        token: props.token,
        visnuCloudFlag: props.visnuCloudFlag,
        skipSlackNotificationActcastIds: props.skipSlackNotificationActcastIds
      })
    }
  }, [props.open, props.visnuCloudFlag, props.skipSlackNotificationActcastIds])

  const form = useForm<UpdateActcastGroupForm>({
    defaultValues: {
      token: props.token,
      visnuCloudFlag: props.visnuCloudFlag ? 'on' : 'off',
      skipSlackNotificationActcastIds: props.skipSlackNotificationActcastIds.map((e) => {
        return { value: e }
      })
    }
  })

  const resetForm = (options: Pick<UpdateActcastGroupDialogProps, 'token' | 'visnuCloudFlag' | 'skipSlackNotificationActcastIds'>) => {
    form.reset({
      token: options.token,
      visnuCloudFlag: options.visnuCloudFlag ? 'on' : 'off',
      skipSlackNotificationActcastIds: options.skipSlackNotificationActcastIds.map((e) => {
        return { value: e }
      })
    })
  }

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'skipSlackNotificationActcastIds'
  })

  const onSubmit = ({ token, visnuCloudFlag, skipSlackNotificationActcastIds }: UpdateActcastGroupForm) => {
    props
      .onSubmit({
        token,
        visnuCloudFlag: visnuCloudFlag === 'on',
        skipSlackNotificationActcastIds: skipSlackNotificationActcastIds.map((e) => e.value)
      })
      .then(() => {
        props.onClose()
      })
  }

  const visnuCloudFlagOptions: UpdateActcastGroupForm['visnuCloudFlag'][] = ['on', 'off']

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{
        onEnter: () => {
          resetForm({
            token: props.token,
            visnuCloudFlag: props.visnuCloudFlag,
            skipSlackNotificationActcastIds: props.skipSlackNotificationActcastIds
          })
        }
      }}
    >
      <Form form={form}>
        <DialogTitle>グループ編集 ({props.groupId})</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="token" label="token" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField type="select" name="visnuCloudFlag" label="visnuCloudFlag" options={visnuCloudFlagOptions} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
            <div>skipSlackNotificationActcastIds</div>
            {fields.map((field, index) => {
              return (
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <input key={field.id} {...form.register(`skipSlackNotificationActcastIds.${index}.value`)} />
                  <FieldButton onClick={() => remove(index)}>削除</FieldButton>
                </Box>
              )
            })}
            <FieldButton onClick={() => append({ value: '' })}>追加</FieldButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            編集する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
