import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export type AddActcastGroupDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (args: { groupId: string; token: string; visnuCloudFlag: boolean }) => Promise<unknown>
}

type AddActcastGroupForm = {
  groupId: string
  token: string
  visnuCloudFlag: 'on' | 'off'
}

export default function AddActcastGroupDialog(props: AddActcastGroupDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm()
    }
  }, [props.open])

  const form = useForm<AddActcastGroupForm>({ defaultValues: { groupId: '', token: '', visnuCloudFlag: 'off' } })

  const resetForm = () => {
    form.reset({ groupId: '', token: '', visnuCloudFlag: 'off' })
  }

  const onSubmit = ({ groupId, token, visnuCloudFlag }: AddActcastGroupForm) => {
    props.onSubmit({ groupId, token, visnuCloudFlag: visnuCloudFlag === 'on' }).then(() => props.onClose())
  }

  const visnuCloudFlagOptions: AddActcastGroupForm['visnuCloudFlag'][] = ['on', 'off']

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onEnter: () => resetForm() }}
    >
      <Form form={form}>
        <DialogTitle>グループ追加</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="groupId" label="グループID" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField name="token" label="token" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField type="select" name="visnuCloudFlag" label="visnuCloudFlag" options={visnuCloudFlagOptions} />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            追加する
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
